
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@-ms-viewport {
  width: device-width;
}
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
    font-family: 'Inter', sans-serif;
  }
 html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
  }
  html, body, #root {
    width: 100%;
    height: 100%;
  }
`;

export default GlobalStyle;
