import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image"
import Ham from '../images/hamburger_menu.svg';
import logo from '../images/logo.svg';


const SignInButton = styled.button`
  padding: 10px 15px;
  background-color: #444CE7;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #444CE7;
  }
  @media (max-width: 768px) {
    display: none; 
  }
`;

const WhiteButton = styled.button`
  padding: 10px 15px;
  background-color: #fff;
  text-color: #475467;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  @media (max-width: 768px) {
    display: none; 
  }
`;

const handleSignup = () => {
  window.open('https://app.inktoivy.com/', '_blank');
};

const handleLogin = () => {
  window.open('https://app.inktoivy.com/', '_blank');
};

const Nav = styled.nav`
  display: flex; 
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    display: none; 
  }
`;

const NavLink = styled.button`
  background: none;
  border: none;
  color: #475467; 
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  transition: color 0.3s;

  &:hover {
    color: #000; 
  }

  &:focus {
    outline: none;
  }
`;

const MobileMenu = styled.div`
  flex-direction: column;
  position: absolute;
  top: 40px;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 5px;
  z-index: 10; 
  display: ${props => {
    console.log("styled ", props.ismenuopennow)
    return props.ismenuopennow === "true" ? "flex" : "none";
  }}
`;

const HamburgerIcon = styled.div`
  width: 30px;
  height: 25px;
  margin-right: 1rem;
  background: url(${Ham}) no-repeat center center; 
  background-size: contain; 
  cursor: pointer;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const HeaderContainer = styled.header`
  margin: 0 auto;
  padding: var(--space-4) var(--size-gutter);
  display: flex;
  align-items: center;
  margin-right: 3rem;
  margin-left: 1rem;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: var(--space-4) var(--size-gutter-mobile);
    margin: 0 auto;
  }
`;

const Header = ({ siteTitle, onMenuItemClick }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  console.log("isMobileMenuOpen", isMobileMenuOpen)
  return (
    <HeaderContainer>
      <img
        src={logo}
        height={80}
      />
      <div style={{
        display: `flex`,
        position: 'relative',
        gap: `1rem`,
      }} >
        <Nav>
          <NavLink onClick={() => onMenuItemClick('home')}>Home</NavLink>
          <NavLink onClick={() => onMenuItemClick('testimonials')}>Testimonials</NavLink>
          <NavLink onClick={() => onMenuItemClick('demo')}>Demo</NavLink>
          <NavLink onClick={() => onMenuItemClick('benefits')}>Benefits</NavLink>
          <NavLink onClick={() => onMenuItemClick('features')}>Features</NavLink>
          <NavLink onClick={() => onMenuItemClick('faqs')}>FAQs</NavLink>
        </Nav>
        <WhiteButton onClick={handleLogin}>Log in</WhiteButton>
        <SignInButton onClick={handleSignup}>Sign up</SignInButton>
        <HamburgerIcon onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)
        } />
        {isMobileMenuOpen && (
          <MobileMenu ismenuopennow={isMobileMenuOpen.toString()}>
            <NavLink onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen)
              return onMenuItemClick('home');
            }}>Home</NavLink>
            <NavLink onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen)
              return onMenuItemClick('testimonials');
            }}>Testimonials</NavLink>
            <NavLink onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen)
              return onMenuItemClick('demo');
            }}>Demo</NavLink>
            <NavLink onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen)
              return onMenuItemClick('benefits');
            }}>Benefits</NavLink>
            <NavLink onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen)
              return onMenuItemClick('features');
            }}>Features</NavLink>
            <NavLink onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen)
              return onMenuItemClick('faqs');
            }}>FAQs</NavLink>
          </MobileMenu>
        )}
      </div>
    </HeaderContainer>);
};

export default Header
