import React, { useRef, useCallback } from 'react';
import { useStaticQuery, graphql, navigate } from "gatsby"
import GlobalStyle from '../styles/GlobalStyle';
import Header from "./header"
import Footer from './footer';
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const handleScrollToSection = useCallback((sectionId) => {
    const section = document.getElementById(sectionId);
    if (section === null) {
      navigate(`/#${sectionId}`);
    } else {
      console.log("section", section)
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);


  return (
    <>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} onMenuItemClick={handleScrollToSection} />
      <main>{children}</main>
      <Footer onMenuItemClick={handleScrollToSection} />
    </>
  )
}

export default Layout
