// src/components/Footer.js

import React from 'react';
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image"

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem; 
  padding: 3rem;
  background-color: #F9FAFB; 
  border-top: 1px solid #dee2e6; 
  @media (max-width: 768px) {
    padding: var(--space-4) var(--size-gutter);
  }
`;

const LogoAndNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%; 
  text-align: left;
  text-color: #667085;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Menu = styled.nav`
  display: flex;
  align-items: center;
  gap: 1rem; 
  flex:1;
  @media (max-width: 768px) {
    justify-content: start; 
    flex-wrap: wrap;
    gap: 0.5rem; 
    padding-top: 1rem;
  }
`;

const MenuItem = styled.a`
  text-decoration: none;
  color: #475467; 
  font-weight: 600;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const MenuItem2 = styled.a`
  text-decoration: none;
  color: #667085; 
  font-weight: 400;
  padding: 0 0.3rem 0 0;
  font-size: 16px;
  text-color: #667085;
  &:hover {
    text-decoration: underline;
  }
`;

const Legal = styled.div`
  color: #6c757d; 
  font-size: 0.875rem; 
`;

const Divider = styled.hr`
  width: 100%; 
  border: 0;
  height: 1px;
  background-color: #dee2e6; 
  margin: 1rem 0; 
`;

const Footer = ({ onMenuItemClick }) => (
  <FooterContainer>
    <LogoAndNav>
      <div style={{ flex: 1 }} >
        <StaticImage
          src="../images/logo_2x.svg"
          loading="eager"
          height={80}
        />
      </div>
      <Menu>
        <MenuItem onClick={() => onMenuItemClick('home')}>Home</MenuItem>
        <MenuItem onClick={() => onMenuItemClick('testimonials')}>Testimonials</MenuItem>
        <MenuItem onClick={() => onMenuItemClick('demo')}>Demo</MenuItem>
        <MenuItem onClick={() => onMenuItemClick('benefits')}>Benefits</MenuItem>
        <MenuItem onClick={() => onMenuItemClick('features')}>Features</MenuItem>
        <MenuItem onClick={() => onMenuItemClick('faqs')}>FAQs</MenuItem>
      </Menu>
    </LogoAndNav>
    <Divider />
    <LogoAndNav>
      © 2024 Ink to Ivy. All rights reserved.
      <Legal>
        <MenuItem2 href="/tos">Terms</MenuItem2>
        <MenuItem2 href="/privacy-policy">Privacy</MenuItem2>
      </Legal>
    </LogoAndNav>
  </FooterContainer>
);

export default Footer;
